<template>
    <div class="culture2">
        <div class="culture2_info">
            <p>专注&nbsp;&nbsp;创新&nbsp;&nbsp;进取</p>
            <span>Concentrate&nbsp;&nbsp;/&nbsp;&nbsp;innovation&nbsp;&nbsp;/&nbsp;&nbsp;progression</span>
        </div>
        <!-- <div class="culture2_bd"></div> -->
    </div>
</template>

<script>
export default {
  name: 'Culture'
}
</script>

<style lang="less" scoped>
.culture2 {
    position: absolute;
    top: calc(20% + 5rem);
    left: calc(45% + 15rem);
    .culture2_info {
        p{
            font-size: 3.5rem;
            font-family: DOUYU;
            font-weight: normal;
            color: #FFFFFF;
            line-height: 3rem;
            margin-bottom: 1.875rem;
        }
        span{
            font-size: 1.3rem;
            font-family: DOUYU;
            font-weight: normal;
            color: #FFFFFF;
            line-height: 3rem;
        }
    }
    .culture2_bd {
        margin: 0 auto;
        width: 70px;
        height: 12px;
        border-bottom: 5px solid #ffffff;
    }
}
</style>
