<template>
  <div id="homeNew">
    <!-- header -->
    <div class="home_bg w100">
      <img src="../assets/imgs/home_back1.png" alt="zhuye.png" class="w100" />
      <Header> </Header>
      <Culture> </Culture>
    </div>
    <!-- IDEA  -->
    <div class="idea_container">
      <div class="idea wrem">
        <div class="idea_l">
          <img src="../assets/imgs/idea.png" alt="经营理念" />
          <div class="idea_info">
            <p>专注行业 应用为本</p>
            <p>构建工会服务新生态</p>
          </div>
        </div>
        <div class="idea_r">
          <img src="../assets/imgs/culture.png" alt="企业文化" />
          <div class="idea_info">
            <p>技术创新 客户满意</p>
            <p>产品领先 服务优质</p>
          </div>
        </div>
      </div>
    </div>
    <!-- INTROUDCE -->
    <div class="about wrem">
      <!-- <img src="../assets/imgs/about.png" alt="公司简介标题 " class="title" /> -->
      <div class="about_container">
        <div class="about_con_l">
          <p>
            北京雷博优尼科技有限公司是一家专注于智慧工会平台开发和实施服务的信息技术公司。
          </p>
          <p>
            自2010年起，团队进入工会服务信息化领域，启动工会信息化服务管理系统的研制开发，并陆续推出数字工会管理软件1.0、2.0、3.0系列产品。
          </p>
          <p>
            公司致力于SAAS平台系统的精细、精准的服务内涵，为企业信息化智慧化工会平台建设提供高品质的软件产品和“指尖化”的便捷服务。
          </p>
        </div>
        <div class="about_con_r">
          <img src="../assets/imgs/home_list1.png" alt="咨询专家" />
          <img src="../assets/imgs/home_list2.png" alt="信息化专家" />
          <img src="../assets/imgs/home_list3.png" alt="工程师" />
          <img src="../assets/imgs/home_list4.png" alt="服务顾问" />
        </div>
      </div>
    </div>
    <!-- NEWS + MAIN business-->
    <div class="mainNew wrem">
      <div class="mainNew_t">
        <a @click="gtoClient()" class="pro_head">
          <img src="@/assets/imgs/news.png" alt="" class="title title1" />
        </a>
        <a @click="gtoClient()">
          <div class="mainNew_t_more">查看更多</div>
        </a>
      </div>
      <div class="news">
        <div class="content">
          <a @click="gtoListFst()">
            <img src="@/assets/imgs/phone.jpg" alt="" />
            <h3>工惠优选平台系统贯通电商平台壁垒</h3>
            <p>2023-02</p>
          </a>
        </div>
        <div class="content">
          <a @click="gtoListSec()">
            <img src="@/assets/imgs/happy.jpg" alt="" />
            <h3>中国劳动关系学院依托智慧工会服务...</h3>
            <p>2022-08</p>
          </a>
        </div>
        <div class="content">
          <a @click="gtoListThird()">
            <img src="@/assets/imgs/news/2023-3-8_motherDay.jpg" alt="" />
            <h3>
              庆祝“三八”国际劳动妇女节
              —《形象美学与服装搭配》讲座活动
            </h3>
            <p>2023-03</p>
          </a>
        </div>
      </div>
      <div class="mainNew_more">
        <div v-for="(item, index) in this.mainNew" :key="index">
          <div class="mainNew_more_item" v-if="index >= 3 && index <= 8">
            <div class="mainNew_m_date">{{ item.times }}</div>
            <div class="mainNew_m_info">{{ item.title }}</div>
            <div class="mainNew_m_more">
              <a @click="gtoNews(item, index)">了解更多</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- MAIN BUSINESS -->
    <div class="business wrem">
      <a @click="gtoProduct()" class="pro_head">
        <img src="../assets/imgs/business.png" alt="产品中心.png" class="title" />
      </a>
      <div class="business_list">
        <div class="business_info">
          <span> · </span>智慧工会服务管理平台系统
        </div>
        <div class="business_info">
          <span> · </span>激励服务管理平台系统
        </div>
        <div class="business_info"><span> · </span>工惠优选平台</div>
        <div class="business_info"><span> · </span>工惠通APP</div>
        <div class="business_info">
          <span> · </span>工惠优选平台系统
        </div>
        <div class="business_info"><span> · </span>工惠生活广场</div>
        <div class="business_info">
          <span> · </span>慈善募捐管理平台
        </div>
        <div class="business_info">
          <span> · </span>企业后勤服务直通车
        </div>
      </div>
    </div>
    <!-- SERVICE -->
    <div class="service wrem">
      <a @click="gtoService()" class="pro_head">
        <img src="../assets/imgs/service.png" alt="服务支持@2x.png" class="title" />
      </a>
      <el-row class="service_top">
        <el-col :span="6" :offset="4">
          <div class="service_t_l">
            <p><span>·</span>持续沟通</p>
            <p><span>·</span>持续、及时地服务</p>
            <p><span>·</span>持续系统升级</p>
            <p><span>·</span>持续的完善和提高</p>
          </div>
        </el-col>
        <el-col :span="5" :offset="4">
          <div class="service_t_r">
            <p><span>·</span>沟通联系方式的便利性</p>
            <p><span>·</span>沟通练习时间的便利性</p>
            <p><span>·</span>沟通联系人员的便利性</p>
            <p><span>·</span>服务执行的便利性</p>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="30" class="service_bottom">
        <el-col :span="7">
          <div class="service_b_l">
            <p><span>·</span>问题及需求</p>
            <p><span>·</span>问题产生的原因</p>
            <p><span>·</span>需求提出的根源及目标</p>
            <p><span>·</span>时间、地点及方式</p>
          </div>
        </el-col>
        <el-col :span="10">
          <img src="../assets/imgs/radius.png" alt="线条@2x.png" class="service_b_i" />
        </el-col>
        <el-col :span="7">
          <div class="service_b_r">
            <p><span>·</span>低成本的沟通</p>
            <p><span>·</span>低成本的服务执行</p>
            <p><span>·</span>低成本的系统应用</p>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- Footer -->
    <Footer></Footer>
  </div>
</template>

<script>
import Culture from "../components/Culture/CultureSec.vue";
import Header from "../components/Header";
import Footer from "../components/Footer/FootSec.vue";
export default {
  name: "HomeNew",
  data() {
    return {
      mainNew: [
        // ---传参方法不能完成实现，采用复制 , 以Home.vue 为准
        //--- 展示方式 ----  前三个带图放在home页上面 往后数6个放home下面（数量可以增加或减少） 最后6个放更多页（更多必须是6个 样式是这样的）
        // 内容content设置8个自然段，超出请自行添加
        //title 标题  // content[0] 是标题下面的 小字标注
        //content[0]之后的是  正文内容  // pic 内容每段附图
        //align : text-align  //content是1  : 流出空白位置 高度：1rem
        //info1
        {
          title: "工惠优选平台系统贯通电商平台壁垒",
          times: "2022-03",
          url: '@/assets/imgs/phone.jpg',
          pic: ['', '', '', '', '', '', '', ''],
          align: 'left',
          content: ['', '2023.2月，经过与京东电商平台的协商，工惠优选平台系统实现了在商品品类、价格、配送、订单管理方面与京东商城产品的统一对接，会员可以在工惠优选平台自由选购，由京东统一配送。', '此次系统的贯通，给工会会员提供了更多、更优的产品选择，更快捷的配送渠道，使工会服务品质上升了到更高的台阶。', '', '', '', '', '', ''],
        },
        // info2
        {
          title: "中国劳动关系学院依托智慧工会服务管理平台系统深化校企合作协同发展模式",
          times: "2022-12",
          url: '@/assets/imgs/happy.jpg',
          pic: ['', '', '', '', '', '', '', ''],
          align: 'left',
          content: [
            '',
            '12月，我公司战略合作伙伴FESCO与中国劳动关系学院就促进工会服务“产、学、研”深度融合签署战略合作协议。本次合作将充分发挥双方资源优势，打造政策研究、教育培训、学术交流成果转化的新高度。',
            '中国劳动关系学院作为全国总工会直属的高等学府，秉承70年发展历史、办学愿景，建设“劳动+”“工会+”学科特色、“一体两翼”发展格局，在工会和劳动关系领域充当科研智库，为企业、行业和社会提供智力支持。 学院坚持协同发展原则，积极开展校企合作、校校合作、校地合作、校会合作，汇聚多方资源推动内涵式发展。'
          ],
        },
        // info3
        {
          title: "庆祝“三八”国际劳动妇女节 —《形象美学与服装搭配》讲座活动",
          times: "2022-03",
          url: '@/assets/imgs/news/2023-3-8_motherDay.jpg',
          pic: ['', '', '', '', '', '', '', ''],
          align: 'left',
          content: [
            '',
            '为庆祝2023年“三八”国际劳动妇女节，体现对女职工的关爱，倡导职场女性既要积极努力工作又要注重衣着打扮的理念，同时也为了使女职工通过穿搭及形象设计课程，体会到得体的着装不仅能给人留下深刻印象，而且可以增加个人魅力的重要性，并收获一份不一样的美丽体验，工会于2023年3月7日-3月8日举办庆“三八”女职工《形象美学与服装搭配》课程讲座活动，使女职工在提升自我休养、发现自身魅力中度过一个有意义的三八节日。'
          ],
        },
        // xiaozi1
        {
          title: "王东明在中国工会十八大报告中指出以习近平新时代中国特色社会主义思想为指导 组织动员亿万职工为强国建设民族复兴团结奋斗",
          times: "2023-10-9",
          url: '',
          pic: ['', '', ''],
          align: 'left',
          content: [
            '',
            '10月9日，全国人大常委会副委员长中华全国总工会主席王东明在作中国工会第十八次全国代表大会报告时，对做好今后五年工会工作提出要求。',
            '在总结新时代我国工运事业的发展和过去五年工作时，王东明指出，党的十八大以来，以习近平同志为核心的党中央始终关心关爱工人阶级，高度重视并有力领导推进党的工运事业和工会工作。亿万职工群众牢记习近平总书记和党中央的殷殷嘱托，在打赢脱贫攻坚战、全面建成小康社会、统筹推进疫情防控和经济社会发展、有效应对风险挑战中发挥了重要作用。各级工会坚持以习近平新时代中国特色社会主义思想为指导，深入贯彻习近平总书记关于工人阶级和工会工作的重要论述，坚定拥护“两个确立”、坚决做到“两个维护”，围绕中心、服务大局，大力加强思想政治引领，团结动员广大职工积极建功新时代，深化产业工人队伍建设改革，切实履行维权服务基本职责，积极构建和谐劳动关心，扎实推进改革创新，全面加强党的建设，推动各项工作取得新进展新成效，奋力谱写了党的工运事业和工会工作新篇章。',
          ],
        },
        {
          title: "2023年快递业“会、站、家”一体化建设在京启动",
          times: "2023-09-27",
          url: '',
          pic: ['', '', ''],
          align: 'left',
          content: [
            '',
            '2023年快递业“会、站、家”一体化建设9月27日在京启动。记者从中国国防邮电工会获悉，截至2023年7月，快递行业“会、站、家”一体化建设已达11600多个，头部快递企业总部均已建立工会组织，并实现了头部快递企业召开职代会和签订集体合同全覆盖。同时，各级工会投入专项补贴及配套资金2000余万元，持续推进2023年“会、站、家”一体化建攻。任中国邮政京乐物物流已签了集本合向的基础上，2023年3月以来，中国国防邮电工会会同全总有关部门和相关省、市总工会，合力推进快递企业开展协商协调机制建设。',
          ],
        },
        {
          title: "全国工会“12351”职工维权服务热线上线试运行",
          times: "2023-09",
          url: '',
          pic: ['', '', ''],
          align: 'left',
          content: [
            '',
            '为顺应新时代职工群众新期待，以数字化、智能化方式履行工会维权服务基本职责，全国总工会9月27日正式启用全总本级“12351”职工维权服务热线呼叫中心，并宣布全国工会“12351”职工维权服务热线上线试运行。',
            '全国工会“12351”职工维权服务热线以接听来电方式，集中受理全国范围内问题咨询、服务帮扶、信访投诉、法律援助、意见建议等职工维权服务需求。热线具有问题解决、统计分析、信息查询、服务评价和全过程监督等5项主要功能，明确了全国一致的问题办理流程、问题解析指标、工单模板、工作手册、统计分析要求等5个方面标准，并就一般问题、难点问题和特别复杂问题3种类型分别明确了办理时限。',
          ],
        },
        {
          title: "全国工会社会联络工作会议暨地方联动机制工作推进会在京召开",
          times: "2023-07-26",
          url: '',
          pic: ['', '', ''],
          align: 'left',
          content: [
            '',
            '全国工会社会联络工作会议暨地方联动机制工作推进会7月26日在京召开，对近年来工会社会联络工作进行总结，交流经验做法，研究部署当前和今后一个时期的目标任务。全国总工会党组书记、副主席、书记处第一书记徐留平出席会议并讲话，对各级工会贯彻落实习近平总书记重要指示批示精神、全力做好工会社会联络工作提出要求。',
            '会议回顾总结了近年来工会社会联络工作取得的积极进展。会议指出，全总和各级工会要深入学习贯彻习近平总书记重要指示批示精神，按照王东明同志要求，坚持以总体国家安全观为指导，将做好工会社会联络工作作为坚决拥护“两个确立”、坚决做到“两个维护”的重要举措紧抓不放，团结引导职工群众听党话、跟党走。要增强信心决心，把握形势风险，进一步提高政治判断力、政治领悟力、政治执行力，以“时时放心不下”的责任感，把“五个坚决”要求落到实处。',
          ],
        },
        {
          title: "全国总工会举行“关爱新就业形态劳动”新闻发布会",
          times: "2023-07-14",
          url: '',
          pic: ['', '', ''],
          align: 'left',
          content: [
            '',
            '全国总工会7月14日举行“关爱新就业形态劳动者”新闻发布会，介绍《深入推进新就业形态劳动者工会工作三年行动计划(2023一2025年)》有关情况和开展新就业形态劳动者工会工作的经验做法。根据三年行动计划”，未来3年将新发展新就业形态劳动者工会会员1000万人，基本实现工会组织对平台企业、头部企业和新就业形态劳动者的全覆盖。',
            '与此同时，各级工会将进一步深化“重点建、行业建、兜底建”模式，构建完善纵横交织、覆盖广泛的工会组织体系，尤其将深化互联网百强企业建会成果，推进互联网企业、平台企业扩面提质，并发挥新业态工会联合会直接发展会员的作用，推进网上入会、“扫码”入会等方式。值得关注的是，全总明确了新就业形态劳动者建会入会的新任务-2023年至2025年，平均每年新发展新就业形态劳动者会员300万人以上，最终达到1000万人入会。',
          ],
        },
        // xiaozi6 多余的 到client1 不显示了
        {
          title: "建党节",
          times: "2023-07-01",
          url: '',
          pic: ['', 'jdj.jpg', ''],
          align: 'center',
          content: [
            '',
            '致岁月铅华，敬红色传承',
          ],
        },
        {
          title: "祖国在我心 筑梦新时代",
          times: "2023-06-30",
          url: '',
          pic: ['', '', '', '', '', '', '', '', '', 'cpjng.png', '', 'cyc.png', '', '', 'jzdzd.png', '', 'wcqs.png', '', 'mfs.png', '', 'jxqbllz.png'],
          align: 'center',
          content: [
            '',
            '传承红色基因、赓续红色血脉',
            '七八月是被红色氛围包围的季节',
            '创新活动方式，丰富活动载体',
            '本期小通带来各式红色主题活动',
            '在体验学习过程中，实现“走心、入脑”',
            '对红色历史进行认识和深入了解',
            '1',
            '1',
            '昌平革命历史纪念馆',
            '革命历史纪念馆位于昌平烈士陵园，由昌平革命历史纪念馆，英雄纪念广场，英雄烈士墓地三部分组成，占地近80亩。纪念馆为上下两层建筑，一层为展厅，分为序言、京华古城燃星火，矢志不渝抗敌寇，红旗漫舞翻新历、尾厅五个部分，以图片和展品的形式展示了从1840年鸦片战争爆发到1949年新中国成立不同时期内，昌平人民的奋斗历史。',
            '长峪城',
            '长峪城位于昌平区西部。东西南三面临山，南邻雕窝沟，地处长峪峡谷，称长峪口，明正德年间筑关城，称长峪城。该城北依长城，南望镇边城，东邻臼羊城，战略位置十分险要，是明代京师北京防御的重要隘口。',
            '1',
            '焦庄户地道战遗址&纪念馆',
            '焦庄户地道战遗址纪念馆分为三个参观区，展馆参观区、地道参观区、抗战民居参观区。馆内收藏的革命文物，主要有挖地道使用的各种工具、民兵使用过的各种武器、群众使用过各种农具等。',
            '舞彩浅山国家徒步道',
            '舞彩浅山步道规划线路总长280公里，串联顺义龙湾屯、木林、张镇、大孙各庄及北石槽等浅山五镇，游览一期步道。',
            '妙峰山',
            '妙峰山风景名胜区，位于北京市西门头沟区境内，距市区55公里，总面积约20平方公里。景区以“古刹”、“奇松”、“怪石”、“异卉”而闻名。属太行山余脉，主峰海拔1291米，山势峭拔，有日出、晚霞、雾凇、山市等时令景观和千亩玫瑰花，有华北地区规模最大的传统朝圣庙会等。',
            '京西情报联络站',
            '平西情报联络站纪念馆，坐落于北京市门头沟区妙峰山旅游景区脚下,由涧沟村的关帝庙改建而成。情报站原址就位于村西的两个农家小院里。2009年4月开馆之际，北京市原市长焦若愚同志亲自题写了馆名。2016年3月，展馆进行了重新改造。',
          ],
        },
        {
          title: "端午安康",
          times: "2023-06-22",
          url: '',
          pic: ['', 'duanwu.jpg', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
          align: 'center',
          content: [
            '',
            '团圆 恰逢其时',
            '生活 “粽”享美好'
          ],
        },
        //client1  取后6个
        {
          title: "《女职工劳动保护特划规定》已经2012年4月 13 日国各院策 200次常务会议通过，现予公布，自公布之日起旅行",
          times: "2023-03",
          url: '',
          pic: ['', 'womenLaborLaw1.png', 'womenLaborLaw2.png', 'womenLaborLaw3.png', 'womenLaborLaw4.png', '', '', ''],
          align: 'left',
          content: [
            ''
          ],
        },
        {
          id: 5,
          title: "全国总工会党组召开扩大会议传达学习全国两会精神",
          times: "2023-03",
          url: '',
          pic: ['', '', '', '', '', '', '', ''],
          align: 'left',
          content: [
            '',
            '全国总工会党组3月14日召开扩大会议传达学习全国两会精神。全国人大常委会副委员长、中华全国总工会主席王东明对全总和各级工会深入学习贯彻全国两会精神特别是习近平总书记系列重要讲话精神提出明确要求。会议强调，各级工会要把思想和行动统一到全国两会精神上来，准确把握工会工作面临的新机遇新要求，在党和国家工作大局中更好发挥工会组织作用，团结动员亿万职工激扬团结奋斗力量，为全面建成社会主义现代化强国、全面推进中华民族伟大复兴作出应有的贡献。',
            '会议强调，全总和各级工会要深刻认识全国两会对形势任务作出的科学判断，按照党的二十大的战略部署，适应实现高质量发展、推进中国式现代化建设的要求，认真履行政治责任和基本职责，不断深化工会改革和建设，把党中央、国务院关于经济社会发展的各项决策部署理解到位、把握到位、落实到位，组织动员亿万职工在推进强国建设、民族复兴的新征程上创造新辉煌、铸就新伟业。'
          ],
        },
        {
          id: 6,
          title: "2023年全国工会县级以上机关干部赴基层蹲点工作启动",
          times: "2023-03",
          url: '',
          pic: ['', '', '', '', '', '', '', ''],
          align: 'left',
          content: [
            '',
            '全总发出通知，启动2023年工会县级以上机关干部赴基层蹲点工作，选派干部深入工会组建难点多、工会工作比较薄弱、劳动关系情况比较复杂的乡镇 (街道)、开发区(工业园区) 工会和县级以下区域性、行业性工会联合会，为基层和职工办实事、解难题。',
            '通知要求，各级工会要把职工思想政治引领、产业工人队伍建设改革、新就业形态劳动者建会入会、构建和谐劳动关系、维护劳动领域政治安全、加强智慧工会建设、县级工会加强年”专项工作等7项工作纳入蹲点任务，省级和市级总工会一般选派3%至5%的机关干部赴基层蹲点。全总机关率先派出8个蹲点组，由局级干部带队，共26名干部陆续前往各地蹲点。',
            '2021年和2022年，全国县级以上工会机关共选派3万多名干部，组成1万多个小组赴基层蹲点，累计访谈基层干部、一线职工54万人，慰问帮扶困难职工35万人，指导新建基层工会组织6万多个，新发展工会会员425万多人，帮助8万余个基层工会实施规范化建设，宣讲劳动法律法规和工会政策2.5万场次，提供法律援助覆盖职工23万人。'
          ],
        },
        {
          title: "全力促进劳动关系和谐稳定-江广平委员代表全国总工会在全国政协十四届一次会议视频会议上的大会发言",
          times: "2023-03",
          url: '',
          pic: ['', '', '', '', '', '', '', ''],
          align: 'left',
          content: [
            '',
            '中共二十大强调，“健全劳动法律法规，完善劳动关系协商协调机制”。3月6日，习近平总书记在看望参加政协会议的民建、工商联界委员时强调，民营企业要在企业内部积极构建和谐劳动关系，推动构建全体员工利益共同体，让企业发展成果更公平惠及全体员工。',
            '为此，建议:',
            '一是多措并举稳就业，端牢“金饭碗”。',
            '二是完善劳动报酬制度，充实“钱袋子”。',
            '三是强化劳动关系三方机制，用好“助推器”。',
            '四是健全劳动关系矛盾预防和调处机制，织密“防护网”。',
            '五是充分发挥工会作用，当好“娘家人”。'
          ],
        },
        {
          id: 11,
          title: "全国总工会发布第二批提升职工生活品质试点单位名单",
          times: "2022-03",
          url: '',
          pic: ['', '', '', '', '', '', '', ''],
          align: 'left',
          content: [
            '',
            '近日，全国总工会下发《关于发布第二批提升职工生活品质试点单位名单的通知》，确定北京市朝阳区工会帮扶中心等20家单位、中国邮政集团有限公司北京市分公司等100家企业(工业园区)为第二批提升职工生活品质全总试点单位。全总对列入试点的帮扶中心安排引导性资金50万元、试点企业(工业园区)安排引导性资金5万元，用干培育创新帮扶服务职工项目， 据了解，2021年9月，全总部署开展提升职工生活品质工作，安排1500万元专项资金予以支持，将20家工会帮扶中心和50家企业(工业园区)纳入全总试点单位。据全总权益保障部有关负责人介绍，截至目前，31个省(区、市)和新疆生产建设兵团总工会均已在全总试点基础上开展省级试点工作，推动落地了一批帮扶服务职工项目，培育储备了一批服务职工基地、关爱职工公益伙伴，形成了一系列因地制宜各具特色的经验做法，取得了一系列可复制可推广的阶段性成果。'
          ],
        },
        {
          id: 12,
          title: "智慧工会服务管理平台系统携手招商银行北京分行 共谋智慧工会服务新蓝图",
          times: "2020-06-23",
          url: '',
          pic: ['', '', '', '', '', '', '', ''],
          align: 'left',
          content: [
            '',
            '2020年6月23日，招商银行股份有限公司北京分行就进驻“智慧工会服务管理平台系统”签署战略合作协议。',
            '招商银行自2019下半年起，围绕客户和科技两大关键点深化战略转型，重新定义银行服务对象和银行服务边界。本次合作，不仅实现了招行内部工会组织的高效运作，更创造了一个链接供应商、工会组织、工会会员的智慧工会服务平台，在提升职工的满意度和获得感的同时，引领和推动智慧工会服务跨越式发展。'
          ],
        },
        {
          id: 13,
          title: "智慧工会服务管理平台系统荣获“2020中国信息技术创新应用”大奖",
          times: "2020-06-30",
          url: '',
          pic: ['', '', '', '', '', '', '', ''],
          align: 'left',
          content: [
            '',
            '6月30日，中国信息协会主办、信息化观察网和中国信息协会传媒中心承办的2020 第三届中国信息技术主管大会在京召开。以“革新·数字化升级”为主题，大会邀请了国内信息化领域权威专家和知名中外企业CIO，探讨新技术、新思维、新模式赋能企业实现业务模式的创新升级变革，并发布了中国信息技术优秀项目。',
            '北京雷博优尼科技有限公司与FESCO强强联合，凭借“智能+”应用敏捷创新和软件开发实力，智慧工会服务管理平台系统荣获“2020中国信息技术创新应用”。',
            '北京雷博优尼科技有限公司具备专业的计算机设计开发能力，时刻关注与传统业务领域的合作，在改变与提升传统业务的模式与效率上投入大力，挖掘和扩展了数字化服务的维度和深度。',
            '智慧工会服务管理平台，助力客户打造智慧工会，实现工会信息化、数字化转型，直达客户工会会员及工会组织。平台通过组织在线、沟通在线和协调在线，实现会员与会员、会员与组织、组织与组织的数字化管理，不仅激发工会创造力，同时实现了工会组织的高效运作，更创造了一个链接供应商、工会组织、工会会员的智慧工会服务平台。'
          ],
        },
      ],
      documentScrollTop: "", // 设置的监听屏幕top的变化
    };
  },
  components: {
    Header,
    Culture,
    Footer,
  },
  methods: {
    gtoClient() {
      this.$router.push({ name: "Client" });
      document.documentElement.scrollTop = 0;
    },
    gtoProduct() {
      document.documentElement.scrollTop = 0;
      this.$router.push("/product");
    },
    gtoService() {
      document.documentElement.scrollTop = 0;
      this.$router.push("/serve");
    },
    gtoListFst() {
      var fstData = JSON.stringify(this.mainNew[0])
      this.$router.push({ name: "News", query: { id: 0, info: fstData } });
      document.documentElement.scrollTop = 0;
    },
    gtoListSec() {
      var secData = JSON.stringify(this.mainNew[1])
      this.$router.push({ name: "News", query: { id: 1, info: secData } });
      document.documentElement.scrollTop = 0;
    },
    gtoListThird() {
      var thirdData = JSON.stringify(this.mainNew[2])
      this.$router.push({ name: "News", query: { id: 2, info: thirdData } });
      document.documentElement.scrollTop = 0;
    },
    gtoNews(item, index) {
      item = JSON.stringify(item)
      this.$router.push({ name: "News", query: { id: index, info: item } });
      document.documentElement.scrollTop = 0;
    },
  },
  mounted() {
    console.log('新闻条数' + this.mainNew.length); //
  }
};
</script>
<style lang="less" scoped>
#homeNew .home_bg {
  position: relative;
}

.idea_container {
  height: 9.5rem;
}

.idea {
  display: flex;
  justify-content: space-around;
  position: relative;
  top: -3.75rem;

  // height: 430px;
  .idea_l {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .idea_r {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .idea_info {
    margin: 4rem 0 0 0;
    font-size: 1.3125rem;
    color: #595757;
    line-height: 2.1875rem;
  }

  img {
    width: 40%;
  }
}

.about {
  padding-top: 0px;
}

.about_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .about_con_l {
    width: calc(65% + 5rem);

    // width: 60%;
    p {
      font-size: 1.25rem;
      color: #666666;
      line-height: 3rem;
    }
  }

  .about_con_r {
    display: flex;
    justify-content: space-between;
    width: calc(32% - 5rem);
    flex-wrap: wrap;

    img {
      width: 38%;
    }
  }
}

.mainNew {
  margin-top: 1rem;

  .mainNew_t {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .mainNew_t_more {
      cursor: pointer;
      border: 2px solid #fe9b00;
      padding: 0.6rem 1rem;
      border-radius: 10px;
      color: #fe9b00;
    }
  }

  .news {
    // width: calc(50% - 10px);
    display: flex;
    justify-content: space-around;

    .title1 {
      margin: 2.4375rem 0 2.5rem 0rem;
    }

    .content {
      width: 28%;

      a {
        cursor: pointer;
      }

      img {
        width: 100%;
      }

      h3 {
        font-size: 1.2rem;
        font-weight: 600;
        color: #333333;
        margin-top: 1.8rem;
        //文字一行显示
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      p {
        margin-top: 1rem;
        font-size: 1.2rem;
        font-weight: 500;
        color: #999999;
      }
    }
  }

  .news>div:last-child {
    border: none;
  }

  .news>div:first-child {
    padding-top: 0px !important;
  }

  .mainNew_more {
    margin-top: 1rem;

    .mainNew_more_item {
      border-bottom: 1px dotted #999999;
      display: flex;
      line-height: 40px;

      .mainNew_m_date {
        width: 7rem;
        font-size: 13px;
        color: #fd6565;
        padding: 0 20px 0 10px;
      }

      .mainNew_m_info {
        font-size: 14px;
        color: #333333;
        max-width: 52rem;
        overflow: hidden; //超出的文本隐藏
        text-overflow: ellipsis; //用省略号显示
        white-space: nowrap; //不换行
      }

      .mainNew_m_more {
        padding: 0 20px 0 20px;

        a {
          cursor: pointer;
          color: #2469af;
        }

        font-size: 13px;
      }
    }
  }
}

.business {
  margin: 1rem auto 0rem;
  padding: 0.0625rem;

  .business_list {
    margin-left: 30px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    span {
      font-weight: 700;
    }

    .business_info {
      padding-left: 0.625rem;
      width: 30%;
      line-height: 3.125rem;
      font-size: 1.25rem;
      font-weight: 500;
    }
  }
}

.service {
  margin-top: 1rem;
  // height: 27rem;
  // margin-bottom: 2.5rem;
  font-size: 1.2rem;
  color: #666666;
  line-height: 2rem;

  // .service_pos {
  // position: relative;
  // top: -5rem;
  .service_top {
    span {
      margin-right: 0.3125rem;
      font-size: 1.5625rem;
    }

    .service_t_l {
      text-align: right;

      span {
        color: #ffda89;
      }
    }

    .service_t_r {
      span {
        color: #94e6eb;
      }
    }
  }

  .service_bottom {
    margin-top: 1rem;

    span {
      margin-right: 0.3125rem;
      font-size: 1.25rem;
    }

    .service_b_l {
      text-align: right;
      margin-top: 5rem;

      span {
        color: #a3dcff;
      }
    }

    .service_b_i {
      width: 100%;
    }

    .service_b_r {
      margin-top: 5rem;

      span {
        color: #b8aff4;
      }
    }
  }

  // }
}
</style>
